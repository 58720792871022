import React, { useCallback, memo, useRef } from "react";
import './styles/App.css';
import MainMenu from "./components/MainMenu";
import Header from "./components/Header"
import GithubUser from './components/GithubUser'
import Popup from 'reactjs-popup';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import { availableDashboardsAPI } from "./http-common";                                                    
import PropagateLoader from 'react-spinners/PropagateLoader';
import useLocalStorage from 'use-local-storage'
import { githubuserAPI } from "./http-common";

// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError, PublicClientApplication, AccountInfo, Configuration } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./utils/MsGraphApiCall";

import 'react-toastify/dist/ReactToastify.css';
import './styles/react-tooltip.css';
import 'reactjs-popup/dist/index.css';

library.add(fas, far, faTwitter, faFontAwesome)


/***
 *        _   ___ ___   ___ _   _ _  _  ___ _____ ___ ___  _  _ 
 *       /_\ | _ \ _ \ | __| | | | \| |/ __|_   _|_ _/ _ \| \| |
 *      / _ \|  _/  _/ | _|| |_| | .` | (__  | |  | | (_) | .` |
 *     /_/ \_\_| |_|   |_|  \___/|_|\_|\___| |_| |___\___/|_|\_|
 *                                                              
 */

const App = ()=> {
  //Switch Dark/Lightmode removed. Static Setting to Lightmode
  //const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  //const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
  const [theme, setTheme] = React.useState('dark');

  //const switchTheme = () => {
  //  const newTheme = theme === 'light' ? 'dark' : 'light';
  //  setTheme(newTheme);
  //}

/***
 *     __  ___      ___  ___  __  
 *    /__`  |   /\   |  |__  /__` 
 *    .__/  |  /~~\  |  |___ .__/ 
 *                                
 */
  
  // *************************************************************************************
  // Dashboard handling States
  // *************************************************************************************
  
  const [shownDashboardID, setShownDashboardID] = React.useState("default");
  const [shownDashboardName, setShownDashboardName] = React.useState("DASH Tool Hub");
  const [newDashboardName, setNewDashboardName] = React.useState("DASH Tool Hub");
  const [dashboardDataAvailable, setDashboardDataAvailable] = React.useState(true)
  const [dashboardFatalError, setDashboardFatalError] = React.useState(false);
  const [firstSyncAfterLoad, setFirstSyncAfterLoad] = React.useState(true);
  const [markedToBeDeleted, setMarkedToBeDeleted] = React.useState({dashboardID: "",dashboardName: ""});

  // *************************************************************************************
  // END Dashboard handling States
  // *************************************************************************************

  const { instance, inProgress, accounts } = useMsal();
  const [graphData, setGraphData] = React.useState(null);

  // State für die Sichtbarkeit des Hauptmenüs
  const [mainMenuStatus, setMainMenuStatus] = React.useState({
      shown: false
  })
   
  /**
   * get ID Token from local Storage
   */
  const getIDToken = useCallback(() => {
    
    //instance.acquireTokenSilent()
        
    let idToken = ""
    let accessToken = ""
    let accessTokenE2E = ""
    let accessTokenMS = ""
    let refreshToken = ""

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if(key.includes("accesstoken")){
        const lsObject = JSON.parse(localStorage.getItem(key))
        if (lsObject.target === "api://ac67ef6f-69d9-4ad1-9c6c-5a54d02bac02/github-user-management") {
          accessTokenE2E = lsObject.secret;
          //console.log("getting github-user-management accesstoken: ", accessTokenE2E);
        } else {
          accessTokenMS = lsObject.secret;
        }
        //console.log("lsObjectMS: ", accessTokenMS);
        //console.log("lsObjectE2E: ", accessTokenE2E);
        accessToken = lsObject.secret;
        // console.log("Access Token: " + accessToken);
      } else if(key.includes("refreshtoken")){
        const lsObject = JSON.parse(localStorage.getItem(key))
        refreshToken = lsObject.secret;
        //console.log("Refresh Token: " + lsObject.secret);        
      } else if(key.includes("idtoken")){
        const lsObject = JSON.parse(localStorage.getItem(key))
        idToken = lsObject.secret;
        //console.log("Gathered Token from LS: " + idToken)
      }      

    }    
    return(accessTokenE2E)

  }, [])

  
  /** 
   * ID Token refresh
   */
  const refreshIDToken = useCallback(async () => {
    let idToken;
    let accessToken;
    const account = instance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: ["api://ac67ef6f-69d9-4ad1-9c6c-5a54d02bac02/github-user-management"],
      account: accounts[0],
    };

    try {
      const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
      accessToken = accessTokenResponse.accessToken;
      idToken = accessTokenResponse.idToken;
      //console.log("Fetched ID Token: " + idToken);
    } catch (error) {
      console.log(error);
    }

    return accessToken;
  }, []);


  // State für dynamische Infos im Seiten Header
  const [headerData, setHeaderData] = React.useState(() => {
    
    let timestamp = new Date();
    timestamp = timestamp.toLocaleString('de-DE');
    let statusText = "Letzte Aktualisierung: " + timestamp;

    return {
      title: "GitHub Tools",
      description: statusText,
      username: " "
    }
  })

  

  /***********************************************************************************************************
  /********************************************* DATENABRUF **************************************************
  /********************************************************************************************************** */  

/**
 * Alle für den User verfügbaren Dashboards abrufen
 * @param {*} userID MS Graph API
 
const fetchDashboards = async (userID) => {  

  const idToken = getIDToken();
  const headers = { 'Authorization': idToken };

  setDashboardDataAvailable(true);
  
  await githubuserAPI.get("/api/user",{headers})
  .then(response => {

    let obj = response.data;
    /*
    setAvailableDashboards(obj)

    console.log("Available dashboards:")
    console.log(obj)    

    const activeDashboardIndex = response.data.findIndex((dashboard) => dashboard.active === true);    

    if(activeDashboardIndex !== -1){

      setShownDashboardID(obj[activeDashboardIndex]["_id"])
      setActiveWidgets(obj[activeDashboardIndex]["widgets"])
      setDashboardCount(obj[activeDashboardIndex]["count"])
      setShownDashboardName(obj[activeDashboardIndex]["name"])
      setSavedOptionsArray(obj[activeDashboardIndex].options)

      obj[activeDashboardIndex].options.forEach(function (arrayItem) {
        
        if(arrayItem.i !== undefined){
          let string = arrayItem.i
          let subBefore= string.substring(0, string.indexOf('_'));
          if(subBefore === "graphcontainer"){
            setGraphDefaultTimerange(arrayItem.graphDefaultTimerange)
            setVisibleGraphs(arrayItem.visibleGraphOptions)
            setReplaceGraph(arrayItem.replaceGraphOption)
          }
        }
      });
      
      setDashboardDataAvailable(true)
    } else {
      setDashboardCount(0)
      setSavedOptionsArray([])
      setDashboardDataAvailable(true)
    }
    
    return obj

  })
  .catch(function (error) {
    console.log("Fetch error available Dashboards" + error)
    setDashboardFatalError(true);
    let obj = [];

    /*
    setTimeout(() => {
      console.log("Erneutes Aufrufen der Dashboard API wird versucht")
      setDashboardFatalError(false)
      fetchDashboards(userID);
    }, 2000);

    return (obj)
  });
  

}  
*/

  /***
   *          __   ___     ___  ___  ___  ___  __  ___  __  
   *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
   *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
   *                                                        
   */

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Abruf der Azure AD Graph Daten
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  React.useEffect(() => {
    
    if (!graphData && inProgress === InteractionStatus.None) {
        callMsGraph()
        .then(response => 
          { 
            //console.log(response)
            refreshIDToken();
            setGraphData(response);
            //fetchDashboards();                       

          })
          
        .catch((e) => {
            if (e instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect({
                    ...loginRequest,
                    account: instance.getActiveAccount()
                });
            }
        });
    } 

  }, [inProgress, graphData, instance]);

  /***
   *     ___            __  ___    __        __  
   *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
   *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
   *                                             
   */




  /**
   * Alert Box Handling
   * Bereitstellung von Alert Boxen die mit den Parametern 'type' und 'message' befüllt werden können
   * @param {*} param 
   */   

  const notify = useCallback((param) => {

    const notifyInfo = (message) => toast.info(message, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    const notifyInfoPerm = (message) => toast.success(message, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
    });

    const notifySuccess = (message) => toast.success(message, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    }); 
    
    const notifyError = (message) => toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    }); 

    const notifyErrorLong = (message) => toast.error(message, {
      position: "bottom-right",
      autoClose: 50000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });     

    const notifyWarn = (message) => toast.warn(message, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });    

    switch (param.type) {
      case 'alert':
        notifyError(param.message)
        break;
      case 'alertLong':
          notifyErrorLong(param.message)
          break;        
      case 'warning':
        notifyWarn(param.message)
        break;
      case 'success':
        notifySuccess(param.message)
      break; 
      case 'infoPerm':
        notifyInfoPerm(param.message)
      break;              
      default:
        notifyInfo(param.message)
    }
  }, [])

  const toggleMainMenuStatus = useCallback((setTo) => {
    //console.log("Main menu") 
    setMainMenuStatus(prevStatus => ({
      ...prevStatus,
      shown: setTo
    }))
  }, [])

    

  /***
   *     __   __            __   ___ ___       __       
   *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
   *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
   *                                                    
   */

  return (
    
    <div className="themeContainer" data-theme={theme}>
      <>
      <ToastContainer />
      
      {/*
      <MainMenu 
        isShown={mainMenuStatus.shown} 
        handleHover={toggleMainMenuStatus}
        handleNotify={notify}
        receiveIDToken={getIDToken}
        handleSwitchTheme={switchTheme}
      />
      */}

      <div className="mainWrap">      
        <Header 
          headerData={headerData} 
          isShown={mainMenuStatus.shown} 
          handleHover={toggleMainMenuStatus}
          userName={graphData ? graphData.displayName : ""}
          receiveIDToken={refreshIDToken}
        />

        <main className="contentWrap">
        
          {dashboardDataAvailable ? 
            <GithubUser 
              handleNotify={notify}
              receiveIDToken={refreshIDToken}
              userName={graphData ? graphData.displayName : ""}
            /> : 
          <>
            <div className="dashLoading">
              {dashboardFatalError ? 
                <>
                  {/*<p className="productWidget--ErrorWrap">Die Dashboard Daten konnten nicht gelesen werden.<br></br>Bitte logge dich aus deinem O365 Konto aus und wieder ein und versuchen es erneut.</p>*/}
                  <p className="productWidget--LoadingWrapText">Lade Dashboard</p>
                  <p><PropagateLoader color={'#6f7277'} size={4} /></p> 
                </>
                :
                <>
                  <p className="productWidget--LoadingWrapText">Lade Dashboard</p>
                  <p><PropagateLoader color={'#6f7277'} size={4} /></p> 
                </>
              }            
            </div> 
          </>

        }

        </main>

      </div>
      </>
    </div>
  );
}

const AppWrap = () => {
  const authRequest = {
      ...loginRequest
  };
  const { accounts } = useMsal();

  const allowedUserIds = [
    "293dd353-cf09-44dd-bb49-70d2cf3ff61e",
    "2620d655-d9d5-404f-ac1a-9bb1c5f0d294",
    "b3f70a9a-b476-4e5b-9939-48b05a2524da",
    "0f87418f-d9f4-4837-a997-301e6ebcdba8",
    "4b0dc69f-f393-4ab3-ab82-29da263fe1f0",
    "7be9f761-52bb-49a1-8695-3917e81f7bb6",
    "b82646cb-958b-4454-aeef-2b7825ab3efc",
    "e859a296-fdcc-4bed-b4a1-926402dbc8b4",
    "a6babb78-cfc7-48ce-b862-298bbf9dd256",
    "ef098b9e-2393-47e2-bc69-9fd5f2801657",    
    "40bee190-826c-4c4d-87a2-6558160d4035",
    "363b37c9-9f15-4762-acde-4bfcab9262dc",
    "ca24e4e3-cb4c-4259-a58d-fca357c5e7cf",
    "e39dda94-a242-4f9b-ab56-4ff9834676de",
    "b6ed9432-cb25-4ba6-a219-9b5b38a7650e"
  ]; // your allowed user IDs
  
  const isAuthenticated = accounts.length > 0;
  const userId = isAuthenticated ? accounts[0].homeAccountId.split(".")[0] : null;
  const isAllowed = allowedUserIds.includes(userId);


  return (
      <MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect} 
          authenticationRequest={authRequest} 
      >
          
          {isAllowed ? <App /> : <NotAllowed />}
      </MsalAuthenticationTemplate>
    )
};

function NotAllowed() {
  return <div className="mainAuthWrap">
            <div className="authContainer">
              <h2>GitHub Userverwaltung</h2>
              <p>Du hast keinen Zugriff auf die GitHub Userverwaltung. Der Zugriff kann im Team: <a href="mailto:tp.sd.rde@freenet.ag">Release & Deployment Engineering</a> angefordert werden.</p>
            </div>
          </div>;
}

export default memo(AppWrap)

